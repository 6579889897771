import ApplicationController from './application_controller';
export default class extends ApplicationController {

  email(event) {
    event.preventDefault();
    const email = document.querySelector('#email');
    const confirm = document.querySelector('#email_confirmation');
    const feedback = document.querySelector('.invalid-feedback');
    const form = document.querySelector('#new_subscription');
    const phone = document.querySelector('#subscription_phone');

    if (phone.value.length !== 10) {
      phone.classList.add('is-invalid');
      feedback.style.display = 'block';
    } else {
        if (email.value !== confirm.value && email.value !== '') {
        email.classList.add('is-invalid');
        confirm.classList.add('is-invalid');
        phone.classList.add('is-invalid');
        feedback.style.display = 'block';
      } else {
        if (form.reportValidity()) form.submit();
      }
    };
  };
}
