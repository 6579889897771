import mapboxgl from "mapbox-gl";
import { mapPopup } from "../components/map_popup";

const initMapbox = () => {
  const mapElement = document.getElementById("map");
  const markers = JSON.parse(mapElement.dataset.markers);

  const fitMapToMarkers = (map, markers) => {
    const bounds = new mapboxgl.LngLatBounds();
    markers.forEach((marker) => bounds.extend([marker.lng, marker.lat]));
    map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0 });
  };

  const addMarkersToMap = (map, markers) => {
    let layers = [];
    let sources = [];

    markers.forEach((marker, i) => {
      const popup = new mapboxgl.Popup().setHTML(marker.infoWindow); // add this

      //code à cleaner
      let el = document.createElement("div");
      const validTypes = ["12p5", "paris_habitat", "rivp", "3F", "covea", "accor", "elogie_siemp", "particulier"];

      if (marker.status === "available_now" && validTypes.includes(marker.type)) {
        el.className = "marker-12p5-available";
      } else if (marker.status === "pre_order" && validTypes.includes(marker.type)) {
        el.className = "marker-12p5-soon";
      } else if (marker.status === "waiting_list" && validTypes.includes(marker.type)) {
        el.className = "marker-12p5-waiting-list";
      } else if (marker.status === "others" && validTypes.includes(marker.type)) {
        el.className = "marker-12p5-others";
      } else if (marker.status === "available_now" && marker.type === "indigo") {
        el.className = "marker-12p5-others";
      } else if (marker.status === "pre_order" && marker.type === "indigo") {
        el.className = "marker-12p5-others";
      } else if (marker.status === "waiting_list" && marker.type === "indigo") {
        el.className = "marker-12p5-others";
      } else if (marker.status === "others" && marker.type === "indigo") {
        el.className = "marker-12p5-others";
      } else if (marker.status === "available_now" && marker.type === "sags") {
        el.className = "marker-sags-available";
      } else if (marker.status === "pre_order" && marker.type === "sags") {
        el.className = "marker-sags-soon";
      } else if (marker.status === "waiting_list" && marker.type === "sags") {
        el.className = "marker-sags-waiting-list";
      } else if (marker.status === "others" && marker.type === "sags") {
        el.className = "marker-sags-others";
      } else if (marker.status === "waiting_list" && marker.type === "saemes") {
        el.className = "marker-saemes-waiting-list";
      } else if (marker.status === "available_now" && marker.type === "saemes"
      ) { el.className = "marker-saemes-available";
      } else if (marker.status === undefined ) {
        el.className = "marker-pin";
      } else if (marker.status === "available_now" && marker.type === "interparking") {
        el.className = "marker-interparking-available";
      } else if (marker.status === "pre_order" && marker.type === "interparking") {
        el.className = "marker-interparking-soon";
      } else if (marker.status === "waiting_list" && marker.type === "interparking") {
        el.className = "marker-interparking-waiting-list";
      } else if (marker.status === "others" && marker.type === "interparking") {
        el.className = "marker-interparking-others";
      }

      new mapboxgl.Marker(el)
        .setLngLat([marker.lng, marker.lat])
        .setPopup(popup) // add this
        .addTo(map);

      el.addEventListener("click", (e) => {
        // Remove all layers
        layers.forEach(layer => {
          map.removeLayer(layer.id);
          layers = [];
        })

        // Remove all sources
        sources.forEach(source => {
          map.removeSource(source.id);
          sources = [];
        })

        setTimeout(() => {
          const origin = markers[markers.length-1];
          const url = `https://api.mapbox.com/directions/v5/mapbox/walking/${origin.lng},${origin.lat};${marker.lng},${marker.lat}?annotations=distance,duration&overview=full&geometries=geojson&access_token=${mapboxgl.accessToken}`
          const coordinates = [marker.lng, marker.lat];
          map.flyTo({
            center: coordinates,
            essential: true
          });

          if (popup) {
            const popupElement = popup.getElement();
            mapPopup(popupElement);
          }

          fetch(url)
            .then(response => response.json())
            .then(data => {
              // Display the walking duration
              const minsOfWalk = Math.round(data.routes[0].duration / 60);
              const directionsInfos = document.querySelector("#directions_infos");
              directionsInfos.innerHTML = `<p style="font-family: Montserrat-Regular";>À <strong>${minsOfWalk}</strong> ${minsOfWalk == 1 ? 'minute' : 'minutes'}</p>`;

              // Display the walking route
              const route = data.routes[0].geometry.coordinates;
              map.addSource(`route-${i}`, {
                'type': 'geojson',
                'data': {
                  'type': 'Feature',
                  'properties': {},
                  'geometry': {
                    'type': 'LineString',
                    'coordinates': route
                  }
                }
              });
              map.addLayer({
                'id': `route-${i}-outline`,
                'type': 'line',
                'source': `route-${i}`,
                'layout': {
                'line-join': 'round',
                'line-cap': 'square'
                },
                'paint': {
                'line-color': '#0029fd',
                'line-width': 2,
                'line-gap-width': 6,
                'line-opacity': 0.8
                }
              });
              map.addLayer({
                'id': `route-${i}-fill`,
                'type': 'line',
                'source': `route-${i}`,
                'layout': {
                'line-join': 'round',
                'line-cap': 'square'
                },
                'paint': {
                'line-color': '#00DDA3',
                'line-width': 6,
                'line-opacity': 0.8
                }
              });

              sources.push(map.getSource(`route-${i}`));
              layers.push(map.getLayer(`route-${i}-outline`));
              layers.push(map.getLayer(`route-${i}-fill`));
            }, 400);
          })
      });
    });
  };

  const addCenterToMap = (map) => {
    let center = map.getCenter();
    let mark = document.createElement("div");
    mark.className = "marker-pin";
    new mapboxgl.Marker(mark).setLngLat([center.lng, center.lat]).addTo(map);
  };

  if (mapElement) {
    mapboxgl.accessToken = mapElement.dataset.mapboxApiKey;
    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v10",
      center: [2.349707199964997, 48.85336510026692], // starting from Notre-Dame
      zoom: 11,
    });

    if (markers.length !== 0) {
      fitMapToMarkers(map, markers);
      addMarkersToMap(map, markers);
      // addCenterToMap(map);
    }

    map.on("dragend", () => {
      const searchZoneButton = document.getElementById("zone");
      searchZoneButton.style.display = "block";
      const center = map.getCenter(); // get center of the map
      fetchAddress(center);
    });

    const fetchAddress = (center) => {
      const urlFetched = `https://api.mapbox.com/geocoding/v5/mapbox.places/${center.lng},${center.lat}.json?access_token=${mapboxgl.accessToken}&types=address`;
      fetch(urlFetched)
        .then((response) => response.json())
        .then(getAndSendAddress);
    };

    const getAndSendAddress = (data) => {
      const address = data.features[0].place_name;
      const urlAddress = address.replaceAll(" ", "+");
      listenToSearchButton(urlAddress);
    };

    const listenToSearchButton = (address) => {
      const searchZoneButton = document.getElementById("zone");
      searchZoneButton.disabled = false;
      let searchParams = new URLSearchParams(window.location.search);
      let followParams = "query[vehicle_type]=" + searchParams.get('query[vehicle_type]') + "&query[vehicle_size]=" + searchParams.get('query[vehicle_size]');
      searchZoneButton.addEventListener("click", (event) => {
        event.preventDefault();
        window.open(
          `${window.location.origin}${window.location.pathname}?address=${address}&${followParams}`,
          "_self"
        );
      });
    };

    map.on("load", () => {
      if (document.querySelector(".overlay")) {
        document.querySelector(".overlay").remove();
      }
    });
  }
};

export { initMapbox };
