import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";

const initFlatpickr = () => {
  if ($(".flatpickr").length > 0) { // Check if .flatpickr elements exist

    $(".flatpickr").flatpickr({
      locale: French,
      enableTime: true,
      minTime: "14:00",
      maxTime: "18:00",
      dateFormat: "d-m-Y H:i",
      altFormat: "j/m/Y H:i",
      altInput: true,
      time_24hr: true,
      minDate: new Date().fp_incr(2), // Set minimum date to 2 days from today
      maxDate: new Date().fp_incr(90),
      onChange: function(selectedDates, dateStr, instance) {
        // This function is triggered when a date is selected
        instance.element.closest("form").requestSubmit();
      },
      disable: [
        function(date) {
          // Disable weekends (Sunday and Saturday)
          return (date.getDay() === 0 || date.getDay() === 6);
        }
      ]
    });

    var currentYear = new Date().getFullYear();

    var holidays = [
      `${currentYear}-01-01`, // Jour de l’an
      `${currentYear}-04-01`, // Lundi de Pâques (note: this might not always be accurate due to Easter's varying date)
      `${currentYear}-05-01`, // Fête du Travail
      `${currentYear}-05-08`, // Fin de la Seconde Guerre mondiale en Europe en 1945
      `${currentYear}-05-09`, // Ascension
      `${currentYear}-05-20`, // Lundi de Pentecôte
      `${currentYear}-07-14`, // Fête nationale
      `${currentYear}-08-15`, // Assomption
      `${currentYear}-11-01`, // Toussaint
      `${currentYear}-11-11`, // Armistice 1918
      `${currentYear}-12-25`  // Jour de Noël
    ];

    // Dynamically add holidays to the disable option
    holidays.forEach(function(holiday) {
      var dateParts = holiday.split('-');
      var year = parseInt(dateParts[0], 10);
      var month = parseInt(dateParts[1], 10) - 1; // JavaScript months are 0-indexed
      var day = parseInt(dateParts[2], 10);

      $(".flatpickr")[0]._flatpickr.config.disable.push({ from: new Date(year, month, day), to: new Date(year, month, day) });
    });
  }
}

export { initFlatpickr };
