const p2rmSearch = () => {

  const btns = document.querySelectorAll(".caret-rotation-js");

  btns.forEach( btn => {
    btn.addEventListener( 'click', ()=> {
      const chevron = btn.querySelector(".chevron-down");
      chevron.classList.toggle('chevron-rotate');
    });
  });
}

export { p2rmSearch };
