const initAutocomplete5 = () => {
  const form = document.querySelector(".trouver-parking3");
  const addressInput = document.getElementById("trouver-parking3");
  const option = {
    types: ['address'],
    componentRestrictions: {country: 'fr'},
    fields: ['address_components', 'geometry', 'icon', 'name']
  }

  let autocomplete = new google.maps.places.Autocomplete(addressInput, option);

  autocomplete.addListener('place_changed', () => {
    // form.submit();
  })
};

export { initAutocomplete5 };
