import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['period', 'single', 'access', 'p2rm', 'hideable'];

  connect() {
    console.log("Connecting Stimulus controller...");

    // Restore previously selected checkbox if it exists
    if (!this.restoreSelectedCheckbox()) {
      // Select the first card element if no checkbox was restored
      this.selectFirstPlanCard();
    }

    // Handle the P2RM switch state
    this.handleP2rmSwitch();

    // Initialize the access checkboxes
    this.initializeAccessCheckboxes();

    // Load previously selected options
    this.loadSelectedOptions();
  }

  saveSelectedCheckbox() {
    const selectedCheckbox = document.querySelector('.form-check-input:checked');
    if (selectedCheckbox) {
      console.log(`Saving selected checkbox ID: ${selectedCheckbox.id}`);
      sessionStorage.setItem('selectedCheckboxId', selectedCheckbox.id);
    }
  }

  restoreSelectedCheckbox() {
    const selectedCheckboxId = sessionStorage.getItem('selectedCheckboxId');
    console.log(`Restoring selected checkbox ID: ${selectedCheckboxId}`);
    if (selectedCheckboxId) {
      const checkbox = document.getElementById(selectedCheckboxId);
      if (checkbox) {
        console.log(`Checkbox found with ID: ${selectedCheckboxId}`);
        checkbox.checked = true;
        checkbox.closest('.plan-card').classList.add('selected');

        // Find and check the second small checkbox (single-payment-true) under the selected card if possible
        const planCard = checkbox.closest('.plan-card');
        if (planCard) {
          const singlePaymentInput = planCard.querySelector('.single-payment-true');
          if (singlePaymentInput) {
            singlePaymentInput.checked = true;
          }
        }
        return true;
      } else {
        console.log(`Checkbox not found with ID: ${selectedCheckboxId}`);
      }
    }
    return false;
  }


  handleP2rmSwitch() {
    const p2rmSwitchValue = sessionStorage.getItem('p2rmSwitch');
    console.log("p2rmSwitch value:", p2rmSwitchValue);

    if (p2rmSwitchValue == 'true') {
      this.activateP2rmMode();
    } else if (p2rmSwitchValue == 'false') {
      this.deactivateP2rmMode();
    } else {
      this.hideP2rmCards();
    }
  }

  activateP2rmMode() {
    this.p2rmTarget.checked = true;
    const normalParts = document.querySelectorAll('.normal-part');
    this.toggleCardsHiddenState(normalParts);
    this.toggleSingleElementHidden('.promocode');
    this.toggleSingleElementHidden('#remise-text');
    this.toggleSingleElementHidden('#application-card');
    this.toggleBadgeCardState(this.p2rmTarget);
  }

  deactivateP2rmMode() {
    const p2rmParts = document.querySelectorAll('.p2rm-part');
    this.toggleCardsHiddenState(p2rmParts);
  }

  hideP2rmCards() {
    document.querySelectorAll('.p2rm-part').forEach((el) => {
      el.hidden = true;
    });

    if (!document.querySelector('.state') || !document.querySelector('.state').dataset.period) {
      this.selectFirstNormalCard();
    }
  }

  restoreState() {
    if (document.querySelector('.state').dataset.period) {
      this.restore();
    } else {
      this.selectFirstPlanCard();
    }
  }

  selectFirstNormalCard() {
    setTimeout(() => {
      document.querySelectorAll('.select-normal')[0].click();
    }, 10);
  }

  selectFirstPlanCard() {
    console.log("Selecting the first plan card");
    const card = document.querySelector('.plan-card');
    if (card) {
      const priceTag = card.querySelector('.price-tag');
      if (priceTag) {
        priceTag.click();
      } else {
        console.log("No element found for .price-tag");
      }

      const singlePaymentInput = card.querySelector('.single-payment-true');
      const multiPaymentInput = card.querySelector('.single-payment-false');
      if (singlePaymentInput) {
        singlePaymentInput.checked = true;
      } else if (multiPaymentInput) {
        multiPaymentInput.checked = true;
      }
      card.classList.add('selected');
    } else {
      console.log("No element found for .plan-card");
    }
  }
  initializeAccessCheckboxes() {
    const accessCheckboxes = document.querySelectorAll('.checkbox-access');
    if (accessCheckboxes.length > 0) {
      let firstElementSelected = accessCheckboxes[0];
      let secondElementSelected = accessCheckboxes[1];

      if (secondElementSelected) {
        secondElementSelected.checked = false;
      }

      if (firstElementSelected) {
        firstElementSelected.checked = true;
        const accessCards = document.querySelectorAll('.access-card');
        if (accessCards.length > 0) {
          accessCards[0].classList.add('selected');
          firstElementSelected.closest("form").requestSubmit();
        }
      }
    }
  }

  periodSelect(event) {
    this.uncheckAll();
    event.currentTarget.checked = true;
    event.currentTarget.closest('.plan-card').querySelector('[data-target="plans.single"]').checked = true;
    event.currentTarget.closest('.plan-card').classList.add('selected');
    this.saveSelectedCheckbox();
  }

  singleSelect(event) {
    this.uncheckAll();
    event.currentTarget.checked = true;
    event.currentTarget.closest('.plan-card').querySelector('[data-target="plans.period"]').checked = true;
    event.currentTarget.closest('.plan-card').classList.add('selected');
    this.saveSelectedCheckbox();
  }

  uncheck(item) {
    item.checked = false;
    item.closest('.plan-card').classList.remove('selected');
  }

  uncheckAll() {
    [...this.periodTargets, ...this.singleTargets].forEach(this.uncheck);
  }

  deselect(item) {
    item.checked = false;
    item.closest('.access-card').classList.remove('selected');
  }

  deselectAll() {
    [...this.accessTargets].forEach(this.deselect);
  }

  accessSelect(event) {
    this.deselectAll();
    event.currentTarget.checked = true;
    const checkbox = event.currentTarget.closest('.access-card').querySelector('[data-target="plans.access"]');
    if (checkbox) {
      checkbox.checked = true;
    }
    event.currentTarget.closest('.access-card').classList.add('selected');

    const badgeCard = document.getElementById('badge-card');
    const badgeDetails = document.querySelector('.badge-details');

    if (event.currentTarget.id === 'badge-card') {
      if (badgeDetails) {
        badgeDetails.classList.remove('d-none');
        badgeCard.classList.add('pt-3');
      }
    } else {
      if (badgeDetails && !badgeDetails.classList.contains('d-none')) {
        badgeDetails.classList.add('d-none');
        badgeCard.classList.remove('pt-3');
      }
    }
  }

  badgeChoice(event) {
    event.stopPropagation();
    const badgeCard = document.getElementById('badge-card');

    this.deselectAll();
    event.currentTarget.checked = true;
    const checkbox = event.currentTarget.closest('.badge-bloc').querySelector('[data-target="plans.accessDelivery"]');
    if (checkbox) {
      checkbox.checked = true;
    }

    const badgeRadioBtn = event.currentTarget.closest('.access-card').querySelector('[data-target="plans.access"]');
    if (badgeRadioBtn) {
      badgeRadioBtn.checked = true;
    }

    const badgeDatetime = document.querySelector('.access-badge-datetime');
    const badgeDetails = document.querySelector('.badge-details');

    if (event.currentTarget.id === "hand-bloc" || event.currentTarget.id === "hand_delivered") {
      badgeDatetime.classList.remove('d-none');
      badgeDetails.classList.remove('w-75');
      badgeDetails.classList.add('w-100');
      badgeCard.classList.add('selected')
    } else {
      badgeDatetime.classList.add('d-none');
      badgeDetails.classList.remove('w-100');
      badgeDetails.classList.add('w-75');
      badgeCard.classList.add('selected')
    }
  }

  submit(event) {
    const latPageException = window.location.href.includes('lat-confirmation-badge');
    if (!latPageException && event.currentTarget.checked == true) {
      event.currentTarget.closest("form").requestSubmit();
    }
  }

  toggleCardsHiddenState(cardsArray) {
    cardsArray.forEach(card => card.hidden = !card.hidden);
  }

  toggleSingleElementHidden(selectorString) {
    const domElement = document.querySelector(selectorString);
    domElement.hidden = !domElement.hidden;
  }

  toggleBadgeCardState(p2rmSwitch) {
    const p2rmMode = p2rmSwitch.checked == true;
    const badgeRadioBtn = document.querySelector('#badge-access');
    const badgePrice = document.querySelector('#badge-price');
    const appliRadioBtn = document.querySelector('#application-access');

    if (p2rmMode) {
      setTimeout(() => {
        badgeRadioBtn.click();
      }, "1")
      badgePrice.innerText = 'Gratuit';
    } else {
      setTimeout(() => {
        appliRadioBtn.click();
      }, "1");
      badgePrice.innerText = '50€';
    }
  }

  apply(event) {
    const p2rmSwitch = event.currentTarget;

    if (p2rmSwitch.checked == true) {
      p2rmSwitch.closest('form').requestSubmit();
      sessionStorage.setItem('p2rmSwitch', 'true');
      document.querySelectorAll('.select-p2rm')[0].click();
    } else {
      sessionStorage.setItem('p2rmSwitch', 'false');
      document.querySelectorAll('.select-normal')[0].click();
    }

    const p2rmParts = document.querySelectorAll('.p2rm-part');
    this.toggleCardsHiddenState(p2rmParts);

    const normalParts = document.querySelectorAll('.normal-part');
    this.toggleCardsHiddenState(normalParts);

    this.toggleSingleElementHidden('.promocode');
    this.toggleSingleElementHidden('#remise-text');
    this.toggleSingleElementHidden('#application-card');

    this.toggleBadgeCardState(p2rmSwitch);
  }

  restore() {
    const period = document.querySelector('.state').dataset.period;
    const single = document.querySelector('.state').dataset.single;
    const priceTag = document.querySelector(`input[value="${period}"]`);

    if (period !== '1') {
      priceTag.click();
      if (single === 'true') {
        priceTag.closest('.plan-card').querySelector('.single-payment-true').checked = true;
        priceTag.closest('.plan-card').classList.add('selected');
      } else {
        priceTag.closest('.plan-card').querySelector('.single-payment-false').checked = true;
        priceTag.closest('.plan-card').classList.add('selected');
      }
    } else {
      priceTag.click();
    }
  }

  latAccessConfirmationContinue(e) {
    const form = document.querySelector('.lat-form');

    const appChosen = document.querySelector('#application-access');
    const accessField = form.querySelector('#access_chosen');
    accessField.value = appChosen.checked ? 'application' : 'badge';

    if (!appChosen.checked) {
      const badgeCollection = form.querySelector('#badge_collection');
      badgeCollection.value = document.querySelector('#hand_delivered').checked ? 'true' : 'false';

      const badgeCollectionDate = form.querySelector('#badge_collection_date');
      badgeCollectionDate.value = document.querySelector('#access_form_scheduled_at').value

      const badgePostal = form.querySelector('#badge_postal');
      badgePostal.value = document.querySelector('#by_post').checked ? 'true' : 'false';
    }

    form.requestSubmit();
  }

  loadSelectedOptions() {
    const selectedOptions = this.getSelectedOptions();
    selectedOptions.forEach(optionId => {
      const checkbox = document.getElementById("sub_option_" + optionId);
      if (checkbox) {
        checkbox.checked = true;
      }
    });
  }

  getSelectedOptions() {
    return JSON.parse(sessionStorage.getItem("selectedOptions")) || [];
  }

  saveSelectedOptions(options) {
    sessionStorage.setItem("selectedOptions", JSON.stringify(options));
  }

  manageOptions(event) {
    const checkbox = event.target;
    const selectedOptions = this.getSelectedOptions();
    const optionId = checkbox.value;

    if (checkbox.checked) {
      selectedOptions.push(optionId);
    } else {
      const index = selectedOptions.indexOf(optionId);
      if (index > -1) {
        selectedOptions.splice(index, 1);
      }
    }

    this.saveSelectedOptions(selectedOptions);
    this.submitFormOption();
  }

  submitFormOption() {
    const formOptions = document.getElementById("options_form");

    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = 'options_form[sub_option_ids][]';
    hiddenField.value = '';

    const selectedOptions = this.getSelectedOptions();
    if (selectedOptions.length === 0) {
      formOptions.appendChild(hiddenField);
    }

    if (formOptions) {
      formOptions.submit();
    }
  }

  continue(event) {
    this.saveSelectedCheckbox();
    document.querySelector('.form-check-input:checked').closest('.plan-card').querySelector('input[type="submit"]').click();
  }
}
