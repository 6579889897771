import { Controller } from "stimulus";

export default class extends Controller{
  static targets = ["card1", "card2"]

  connect(){
    setInterval(() => { this.card1Target.classList.toggle("hidden")
                        this.card2Target.classList.toggle("hidden") }, 2000)
  }
}
