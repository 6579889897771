require('jquery');
require('bootstrap');
require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('share-buttons');

import 'jquery';
import 'bootstrap';
import 'bootstrap-select';

import { deployNavbar } from '../components/navbar';
import carouselConceptCaptions from '../components/carousel_captions';
import addSpec from '../components/add_spec';
import { initMapbox } from '../plugins/init_mapbox';
import { directUploads } from '../plugins/direct_uploads';
import { initSmallMapbox } from '../plugins/init_small_mapbox';
import { backToTop } from '../plugins/back_to_top';
import { getUrl } from '../plugins/get_url';
import { loadDynamicBannerText } from '../components/title';
import { initAutocomplete  } from '../plugins/init_autocomplete';
import { initAutocomplete2 } from "../plugins/init_autocomplete_2";
import { initAutocomplete3 } from "../plugins/init_autocomplete_3";
import { initAutocomplete4 } from "../plugins/init_autocomplete_4";
import { initAutocomplete5 } from "../plugins/init_autocomplete_5";
import { initAutocomplete6 } from "../plugins/init_autocomplete_6";
import { closeModal  } from '../plugins/close_modal';
import { videoScreenSize } from '../plugins/video_screen_size_selector';
import { planCardRemove } from '../plugins/plan_card_remove';
import { initFlatpickr } from "../plugins/flatpickr";
import { p2rmSearch } from "../components/p2rm_search";

require ("moment")
import  { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
window.Calendar = Calendar

$("#toggle-eco-text").on("click", function(event) {
  $("#toggle-eco-text").hide();
  $(".text-2").show();
  $(".part-2").show();
});

initFlatpickr();

$(document).on("change", ".select-type-1", function(e){
  var topic1 = $(this).children("option:selected").val();
  if (topic1) {
    change_select_options(topic1, "[name='query[vehicle_size]']", 0);
    if ($("[name='query[vehicle_size]']")[1])
      change_select_options(topic1, "[name='query[vehicle_size]']", 1);
    if ($("[name='query[vehicle_size]']")[2])
      change_select_options(topic1, "[name='query[vehicle_size]']", 2);
    if ($("[name='query[vehicle_size]']")[3])
      change_select_options(topic1, "[name='query[vehicle_size]']", 3);
    if ($("[name='query[vehicle_size]']")[4])
      change_select_options(topic1, "[name='query[vehicle_size]']", 4);
    if ($("[name='query[vehicle_size]']")[5])
  	  change_select_options(topic1, "[name='query[vehicle_size]']", 5);
  }
});

$(document).ready(function(e){
	var elem = $(".select-type-1");
  var topic1 = elem.children("option:selected").val();
  if (topic1) {
    change_select_options(topic1, "[name='query[vehicle_size]']", 0);
    if ($("[name='query[vehicle_size]']")[1])
      change_select_options(topic1, "[name='query[vehicle_size]']", 1);
    if ($("[name='query[vehicle_size]']")[2])
      change_select_options(topic1, "[name='query[vehicle_size]']", 2);
    if ($("[name='query[vehicle_size]']")[3])
      change_select_options(topic1, "[name='query[vehicle_size]']", 3);
    if ($("[name='query[vehicle_size]']")[4])
      change_select_options(topic1, "[name='query[vehicle_size]']", 4);
    if ($("[name='query[vehicle_size]']")[5])
  	  change_select_options(topic1, "[name='query[vehicle_size]']", 5);
  }
});

function removeAllOptions(selectBox) {
  while (selectBox.options.length > 0) {
      selectBox.remove(0);
  }
}

function change_select_options(text, elem, index) {
	removeAllOptions($(elem)[index]);
	let searchParams = new URLSearchParams(window.location.search);
	if (text == "Vélo classique")
	{
		$(elem)[index].add(new Option("Vélo standard (60x180cm - pneu 55mm max)", 0));
		$(elem)[index].add(new Option("pneus 'Fatbike' 1 (60x180cm - pneu 55 à 80mm)", 1));
		$(elem)[index].add(new Option("pneus 'Fatbike' 2 (60x180cm - pneu 80 à 100mm)", 1));
		$(elem)[index].add(new Option("pneus 'Fatbike' 3 (60x180cm - pneu 100 à 120mm)", 1));
		$(elem)[index].add(new Option("Longtail (60x220cm - pneu 55mm max)", 2));
		let vsize = searchParams.get('query[vehicle_size]')
		if (vsize && (vsize == 0 || vsize == 1 || vsize == 2) )
			$(elem).val(vsize);
	}
	else if (text == "Vélo cargo")
	{
    $(elem)[index].add(new Option("petit (70x200cm - hauteur 180cm max)", 9));
    $(elem)[index].add(new Option("moyen (90x250cm - hauteur 180cm max)", 10));
    $(elem)[index].add(new Option("grand (110x350cm - hauteur 200cm max)", 11));
		let vsize = searchParams.get('query[vehicle_size]')
		if (vsize && (vsize == 9 || vsize == 10 || vsize == 11) )
			$(elem).val(vsize);
	}
	else if (text == "Deux-roues motorisées")
	{
    $(elem)[index].add(new Option("petit (80x180cm)", 3));
    $(elem)[index].add(new Option("moyen (85x220cm)", 4));
    $(elem)[index].add(new Option("grand (100x240cm)", 5));
		let vsize = searchParams.get('query[vehicle_size]')
		if (vsize && (vsize == 3 || vsize == 4 || vsize == 5) )
			$(elem).val(vsize);
	}
	else if (text == "Trottinette")
	{
    $(elem)[index].add(new Option("petit (40x180cm)", 6));
    $(elem)[index].add(new Option("moyen (60x180cm)", 7));
    $(elem)[index].add(new Option("grand (80x200cm)", 8));
		let vsize = searchParams.get('query[vehicle_size]')
		if (vsize && (vsize == 6 || vsize == 7 || vsize == 8) )
			$(elem).val(vsize);
	}
  else if (text == "Personnalisé")
  {
    $(elem)[index].add(new Option("personnalisé petit : 200 x 500cm", 12));
    $(elem)[index].add(new Option("personnalisé moyen : 500 x 500cm", 13));
    $(elem)[index].add(new Option("personnalisé grand : “1000 x 500cm", 14));
		let vsize = searchParams.get('query[vehicle_size]')
		if (vsize && (vsize == 12 || vsize == 13 || vsize == 14) )
			$(elem).val(vsize);
  }
	else
		$(elem)[index].add(new Option('Taille de votre deux-roues*', undefined));


}

document.addEventListener('turbolinks:load', () => {
	deployNavbar();
	carouselConceptCaptions();
	addSpec();
  if (document.querySelector('.caret-rotation-js')) p2rmSearch();
	if (document.querySelector('#map')) initMapbox();
	if (document.querySelector('#small_map')) initSmallMapbox();
	if (document.querySelector('#title-typed-js')) loadDynamicBannerText();
	if (document.querySelector('.edit_parking_pdf')) directUploads();
	if (document.querySelector('#link-back-to-top')) backToTop();
	if (document.querySelector('#getUrlPage')) getUrl();
	if (document.querySelector('#parking_address')) initAutocomplete();
	if (document.querySelector("#parking_address_2")) initAutocomplete2();
	if (document.querySelector("#parking_address_3")) initAutocomplete6();
	if (document.querySelector('#search__button')) closeModal();
	if (document.querySelector('#video_wrapper')) videoScreenSize();
	if (document.querySelector('.plan-card')) planCardRemove();
	if (document.querySelector("#trouver-parking1")) initAutocomplete3();
	if (document.querySelector("#trouver-parking2")) initAutocomplete4();
	if (document.querySelector("#trouver-parking3")) initAutocomplete5();
	if (document.querySelector("#calendar")) eventCalendar(frLocale, timeGridPlugin, interactionPlugin);
});



import 'controllers';
