const deployNavbar = () => {
  $(document).ready(function () {
    $('#navbarDropdown').on('click', function () {
      $('.animated-icon2').toggleClass('open');

      if ($('.dropdown-menu-navbar').hasClass('show')) {
        $('#footer').show();
      } else {
        $('#footer').hide();
      }
    });

    $('.dropdown-menu-navbar').on('click', function (e) {
      if (e.target.id == "trigger-dropdown-2") {
        if($('.second-dropdown').css('display') == 'block') {
          e.stopPropagation();
          $('.second-dropdown').css('display', 'none');
        } else {
          e.stopPropagation();
          $('.second-dropdown').css('display', 'block');
        }
      } else {
        if ($('.dropdown-menu-navbar').hasClass('show')) {
          $('.animated-icon2').toggleClass('open');
          $('#footer').show();
        }
      }
    });
  });
}

export { deployNavbar }
