const carouselConcept = document.getElementById("carouselConcept");

const carouselConceptCaptions = () => {
  if (carouselConcept) {
    const allItems = document.querySelectorAll(".carousel-item");

    // $("#carouselConcept").carousel({
    //   interval: 2000,
    // });

    // $("#carouselConcept").bind("slid", function () {
    //   currentIndex = $("div.active").index() + 1;
    //   console.log(currentIndex);
    //   // $(".num").html("" + currentIndex + "/" + totalItems + "");
    // });
  }
};

export default carouselConceptCaptions;
