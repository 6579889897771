const initAutocomplete = () => {

  const form = document.querySelector('.form-filter');
  const addressInput = document.getElementById('parking_address');
  const option = {
    types: ['address'],
    componentRestrictions: {country: 'fr'},
    fields: ['address_components', 'geometry', 'icon', 'name']
  }

  let autocomplete = new google.maps.places.Autocomplete(addressInput, option);

  autocomplete.addListener('place_changed', () => {
    // form.submit();
  })

};

export { initAutocomplete };
