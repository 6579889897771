const mapPopup = (popupElement) => {
  let contentElement = popupElement.lastChild;
  let infoElement = contentElement.firstChild;

  infoElement.scrollTo(0,0);

  popupElement.addEventListener('touchstart', handleTouchStart, false);        
  popupElement.addEventListener('touchmove', handleTouchMove, false);

  var xDown = null;                                                        
  var yDown = null;

  function getTouches(evt) {
    return evt.touches ||             // browser API
          evt.originalEvent.touches; // jQuery
  }                                                     
                                                                          
  function handleTouchStart(evt) {
      const firstTouch = getTouches(evt)[0];                                      
      xDown = firstTouch.clientX;                                      
      yDown = firstTouch.clientY;                                      
  };                                                
                                                                          
  function handleTouchMove(evt) {
      if ( ! xDown || ! yDown ) {
          return;
      }

      var xUp = evt.touches[0].clientX;                                    
      var yUp = evt.touches[0].clientY;

      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;
                                                                          
      if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
          if ( xDiff > 0 ) {
              /* right swipe */ 
          } else {
              /* left swipe */
          }                       
      } else {
          if ( yDiff > 0 ) {
            /* up swipe */
            if (!contentElement.classList.contains('swiped_up')) {
              contentElement.classList.toggle('swiped_up');
            }
          } else { 
            /* down swipe */ 
            if (contentElement.classList.contains('swiped_up')) {
              contentElement.classList.toggle('swiped_up');
            }
          }                                                                 
      }
      /* reset values */
      xDown = null;
      yDown = null;                                             
  };
};

export { mapPopup };
