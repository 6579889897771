const backToTop = () => {

  const backToTopButton = document.getElementById('link-back-to-top');

  const scrollFunc = () => {
    let y = window.scrollY;

    if (y > (window.innerHeight) * 1.5) {
      backToTopButton.className = "link-back-to-top show";
    } else {
      backToTopButton.className = "link-back-to-top hide";
    }
  };

  window.addEventListener("scroll", scrollFunc);

  const scrollToTop = () => {
    window.scroll({ top: 0, behavior: "smooth" })
  };

  backToTopButton.onclick = function (e) {
    e.preventDefault();
    scrollToTop();
  }
};

export { backToTop }; 
