import ApplicationController from './application_controller';

/* This is the custom StimulusReflex controller for ExampleReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ['plans-details'];
  /* Reflex specific lifecycle methods.
   * Use methods similar to this example to handle lifecycle concerns for a specific Reflex method.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Example:
   *
   *   <a href="#" data-reflex="ExampleReflex#example">Example</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "ExampleReflex#example"
   *
   *   error - error message from the server
   */
  // beforeUpdate(element, reflex) {
  //  element.innerText = 'Updating...'
  // }
  // updateSuccess(element, reflex) {
  //   element.innerText = 'Updated Successfully.'
  // }
  // updateError(element, reflex, error) {
  //   console.error('updateError', error);
  //   element.innerText = 'Update Failed!'
  // }

  connect() {
    this.setupTabListeners();
  }

  setupTabListeners() {
    // Query all nav-link elements within nav-item components
    const tabLinks = document.querySelectorAll('.nav-item .nav-link');

    // Iterate over each link to attach click event handlers
    tabLinks.forEach(link => {
      link.addEventListener('click', () => {
        this.handleTabClick(link);
      });
    });
  }

  handleTabClick(clickedLink) {
    const abonnementContent = document.querySelector('#abonnement');

    if (clickedLink.id === 'abonnement-tab') {
      // If the 'abonnement-tab' is clicked, remove 'd-none' to show its content
      abonnementContent.classList.remove('d-none');
    } else {
      // If any other tab is clicked, add 'd-none' to hide 'abonnement' content
      abonnementContent.classList.add('d-none');
    }
  }


  close_row(event) {
    const id = event.currentTarget.dataset.id;
    const plans = document.querySelector(`#accordion-${id}-plans`);

    if (plans.classList.contains('show')) {
      plans.classList.remove('show');
    }
  }


}
