import Typed from 'typed.js';

const loadDynamicBannerText = () => {
  new Typed('#title-typed-js', {
    strings: [
      "Vélo",
      "Vélo éléctrique",
      "Vélo cargo",
      "Trottinette",
      "Scooter",
      "Moto"
    ],
    typeSpeed: 190,
    loop: true
  });
}

export {
  loadDynamicBannerText
};