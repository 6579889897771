import mapboxgl from 'mapbox-gl';

const smallMapElement = document.getElementById('small_map');
const previewMaps = document.querySelectorAll('.preview_map');

const buildMap = ([{ lat, lng }], container) => {
  mapboxgl.accessToken = smallMapElement.dataset.mapboxApiKey;
  return new mapboxgl.Map({
    container: container,
    style: 'mapbox://styles/mapbox/streets-v10',
    center: [lng, lat],
    zoom: 15,
    interactive: false
  });
};

const addMarkersToMap = (map, markers) => {
  markers.forEach(marker => {
    let el = document.createElement('div');
    new mapboxgl.Marker(el).setLngLat([marker.lng, marker.lat]).addTo(map);
  });
};

const initSmallMapbox = () => {
  const markers = JSON.parse(smallMapElement.dataset.markers);

  if (smallMapElement) {
    const map = buildMap(markers, 'small_map');
    addMarkersToMap(map, markers);
  }

  if (previewMaps) {
    previewMaps.forEach(previewMap => {
      const smallMarkers = JSON.parse(previewMap.dataset.markers);
      const slug = previewMap.dataset.slug;
      const map = buildMap(smallMarkers, `preview_map-${slug}`);
      addMarkersToMap(map, smallMarkers)
    });
  }
};

export { initSmallMapbox };
