const planCardRemove = () => {
    const planCardMobile = document.querySelectorAll('.plan-container-mobile');
    const planCard = document.querySelectorAll('.plan-container');
    if (window.screen.width <= 425) {
        planCard.forEach(el => el.remove());
    } else {
        planCardMobile.forEach(el => el.remove());
    }
}

export { planCardRemove };