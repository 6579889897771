const initAutocomplete3 = () => {
  const form = document.querySelector(".trouver-parking1");
  const addressInput = document.getElementById("trouver-parking1");
  const option = {
    types: ['address'],
    componentRestrictions: {country: 'fr'},
    fields: ['address_components', 'geometry', 'icon', 'name']
  }
  
  let autocomplete = new google.maps.places.Autocomplete(addressInput, option);

  autocomplete.addListener('place_changed', () => {
    // form.submit();
  })
};

export { initAutocomplete3 };
