import { Controller } from "stimulus"

// A Stimulus Controller to geolocate the user and return the relevant info on the closest parking
export default class extends Controller {

  static geolocationOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  }

  errorGeolocation(error) {
    alert(`ERROR(${error.code}): ${error.message} Votre localisation n'a pas pu etre trouvé`);
  }

  successfulGeolocation(position) {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;

    // Fetch from API route that return json with closest parkings
    const geoLocUrl = `${document.location.protocol}//${document.location.host}/api/v2/admin/nearby_parkings?lat=${lat}&lng=${lng}`;
    fetch(geoLocUrl)
    .then(response => response.json())
    .then(parkings => {
        //Update the DOM with the info for each of the parkings
        const list = document.getElementById('parkingsList');

        parkings.forEach(parking => {
          const listItem = document.createElement('li');
                listItem.classList.add("admin-access__li");

          const parkingName = document.createElement('h2');
                parkingName.innerText = parking.name;
          const parkingAddress = document.createElement('p');
                parkingAddress.innerText = parking.address;
          listItem.appendChild(parkingName);
          listItem.appendChild(parkingAddress);

          if (parking.active) {
            // Create an array of all the doors for the current parking
            const parkingDoors = [
              {
                doorName: "phone_entry_1",
                doorNum: parking.phone_entry_1
              },
              {
                doorName: "phone_entry_2",
                doorNum: parking.phone_entry_2
              },
              {
                doorName: "phone_entry_3",
                doorNum: parking.phone_entry_3
              },
              {
                doorName: "phone_exit_1",
                doorNum: parking.phone_exit_1
              },
              {
                doorName: "phone_exit_2",
                doorNum: parking.phone_exit_2
              },
              {
                doorName: "phone_exit_3",
                doorNum: parking.phone_exit_3
              },
            ];

            // Div to hold all the buttons
            const doorOpenButtonContainer = document.createElement('div');
                  doorOpenButtonContainer.classList.add('admin-access__btn-container');

            // Create Buttons for each truthy value in the array
            parkingDoors.forEach((doorObj, index) => {
              if (doorObj.doorNum) {
                //Create a button that will call that gate
                const openButton = document.createElement('button');
                      openButton.innerText = `Porte ${index + 1}`;
                // Add a click event listener that will open the corresponding door
                const callUrl = `${document.location.protocol}//${document.location.host}/api/v2/parkings/${parking.id}/calls`;
                openButton.addEventListener('click', async _ => {
                  try {
                    await fetch(callUrl, {
                      method: 'post',
                      headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({"gate": doorObj.doorName})
                    });
                  } catch(err) {
                    alert(`Probleme d'ouverture de porte. Error: ${err}`);
                  }
                });
                doorOpenButtonContainer.appendChild(openButton);
              }
            });

            listItem.appendChild(doorOpenButtonContainer);
          } else {
            // When parking is inactive - display a string explaining absence of buttons
            const parkingInactiveMessage = document.createElement('p');
                  parkingInactiveMessage.innerText = "Parking Inactif : aucune porte trouvée";
            listItem.appendChild(parkingInactiveMessage);
          }
          list.appendChild(listItem);
        });
      });
  }

  connect() {
    // Geolocate the user on page load
    navigator.geolocation.getCurrentPosition(this.successfulGeolocation, this.errorGeolocation, this.geolocationOptions)
  }
}
