import ApplicationController from './application_controller';

/* This is the custom StimulusReflex controller for ExampleReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */

import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = [];

  setFormValuesFromSession() {
    const isDataInSessionStorage = sessionStorage.getItem('vehicleGroup');

    if (isDataInSessionStorage) {
      this.#setInputValue('.vehicle_group_select', 'vehicleGroup');
      this.#setInputValue('.vehicle_size_select', 'vehicleSize');
      this.#setInputValue('.vehicle_numberplate_input', 'vehicleNumberplate');
      this.#setInputValue('.vehicle_colour_input', 'vehicleColourplate');
      this.#setInputValue('.vehicle_electric_check', 'vehicleElectric');
    }
  }

  #setInputValue(inputSelector, sessionStorageKey) {
    const sessionStorageValue = sessionStorage.getItem(sessionStorageKey);

     if (sessionStorageKey === 'vehicleGroup') {
      this.#revealCorrespondingVehicleForm(sessionStorageValue);
    }
    const input = document.querySelector(inputSelector);
    input.value = sessionStorageValue;
  }

  #revealCorrespondingVehicleForm(chosenGroup) {
    // The different form elements for each vehicle group
    const subscriptionId = this.element.dataset.subscription;

    let classicForm, motoForm, cargoForm, skateForm, personalizedForm;

    if (!subscriptionId || subscriptionId === "") {
      classicForm = document.getElementById('classic_form');
      motoForm = document.getElementById('moto_form');
      cargoForm = document.getElementById('cargo_form');
      skateForm = document.getElementById('skate_form');
      personalizedForm = document.getElementById('personalized_form');
    } else {
      classicForm = document.getElementById(`classic_form_${subscriptionId}`);
      motoForm = document.getElementById(`moto_form_${subscriptionId}`);
      cargoForm = document.getElementById(`cargo_form_${subscriptionId}`);
      skateForm = document.getElementById(`skate_form_${subscriptionId}`);
      personalizedForm = document.getElementById(`personalized_form_${subscriptionId}`);
    };

     // Hide form elements from view
     const forms = [classicForm, motoForm, cargoForm, skateForm, personalizedForm];
     forms.forEach(form => form.hidden = true);

     // Reveal only the form corresponding to the vehicule group chosen by the user
     switch (chosenGroup) {
       case "Vélo classique":
         classicForm.hidden = false;
         break;
       case "Deux-roues motorisés":
         motoForm.hidden = false;
         break;
       case "Trottinette":
         skateForm.hidden = false;
         break;
       case "Vélo cargo":
         cargoForm.hidden = false;
         break;
      case "Personnalisé":
         personalizedForm.hidden = false;
         break;
       default:
         classicForm.hidden = false;
         break;
     }
  }

  vehicleGroupSwitch(event) {
    // The group selected by the user
    const chosenGroup = event.target.value;

    // Save the group selected to the browser's sessionsStorage
    sessionStorage.setItem("vehicleGroup", chosenGroup);

    this.#revealCorrespondingVehicleForm(chosenGroup);
  }

  storeSizeChoice(event) {
    const size = event.target.value;
    sessionStorage.setItem("vehicleSize", size);
  }

  storeNumberplateValue(event) {
    const numberplate = event.target.value;
    sessionStorage.setItem("vehicleNumberplate", numberplate);
  }

  storeColourValue(event) {
    const colour = event.target.value;
    sessionStorage.setItem("vehicleColour", colour);
  }

  storeElectricChoice(event) {
    const electric = event.target.value;
    sessionStorage.setItem("vehicleElectric", electric);
  }

  select(event) {
    Rails.ajax({
      type: 'get',
      url: `/vehicle_select?vehicle_id=${event.target.value}`,
      data: ''
    });
  }

  custom(event) {
    event.preventDefault();
    const email = document.querySelector('#email');
    const confirm = document.querySelector('#email_confirmation');
    const feedback = document.querySelector('.invalid-feedback');
    const form = document.querySelector('#new_custom_request');

    if (email.value !== confirm.value && email.value !== '') {
      email.classList.add('is-invalid');
      confirm.classList.add('is-invalid');
      feedback.style.display = 'block';
    } else {
      if (form.reportValidity()) form.submit();
    }
  }

  reflexSuccess(element) {
    if (element.value === 'Custom' && document.querySelector('.range')) {
      const range = document.querySelector('input[type="range"]');
      const rangeV = document.getElementById('rangeV');
      const setValue = () => {
        const newValue = Number(
          ((range.value - range.min) * 100) / (range.max - range.min)
        );
        const newPosition = 10 - newValue * 0.2;
        rangeV.innerHTML = `<span>à&nbsp;partir&nbsp;de&nbsp;${range.value * range.dataset.price}€/mois&nbsp;${Math.round((((newValue/100)*160)+40), 1)}&nbsp;cm</span>`;
        rangeV.style.left = `calc(${newValue}% + (${newPosition}px))`;
      };

      document.addEventListener('DOMContentLoaded', setValue);
      range.addEventListener('input', setValue);
    }
  }
}
