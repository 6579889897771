const videoScreenSize = () => {
    const videoWeb = document.querySelector('#player_video');
	const videoInsta = document.querySelector('#player_mobile');
	const borderVideo = document.querySelector('#border_video');

	if (window.screen.width <= 425) {
		videoWeb.parentNode.removeChild(videoWeb);
		borderVideo.parentNode.removeChild(borderVideo);
		videoInsta.style.display = 'block';
	} else {
		videoInsta.parentNode.removeChild(videoInsta);
	}
}

export { videoScreenSize };