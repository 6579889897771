const addSpecsButtons = document.querySelectorAll('.add-spec-button');

const addSpec = () => {
  const handleClick = (event) => {
    event.preventDefault();
    const parkingId = event.target.dataset.parkingId;
    const form = document.querySelector(`#edit_parking_${parkingId}`);
    const inputField = form.querySelector('#parking_');
    const clone = inputField.cloneNode(true);
    clone.value = "";
    event.target.parentNode.insertBefore(clone, event.target);
  };
  
  if (addSpecsButtons) {
    addSpecsButtons.forEach(addSpecButton => {
      addSpecButton.addEventListener('click', handleClick);
    });
  }
};

export default addSpec;
