const closeModal = () => {
    const buttonSearch = document.querySelector('#search__button');
    const filterModal = document.querySelector('#filterModal');
    
    if (buttonSearch) {
        buttonSearch.addEventListener("click", () => {
            const backdrop = document.querySelector('.modal-backdrop');
            filterModal.classList.remove('show');
            filterModal.setAttribute('aria-hidden', 'true');
            filterModal.setAttribute('style', 'display: none');
            document.body.removeChild(backdrop);
            document.body.style.overflow = "scroll";
        });
    }
}

export { closeModal };